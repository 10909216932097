import axios from "axios";
import { getToken } from "./auth";

  // export const baseURL = "http://localhost/bc_commercial_laravel";
//  export const baseURL = "http://localhost:8000";
 export const baseURL = "https://com-api.bettercare.tech";

const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
