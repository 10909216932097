import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/GlobalStyle.css";

import ReactDOM from "react-dom";
import { SpinnerComponent } from "./components/Spinner";
import AuthProvider from "./context/AuthContext";

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
const queryClient = new QueryClient();

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Suspense fallback={<SpinnerComponent />}>
          <LazyApp />
        </Suspense>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
